import logo from './logo.svg'
import './App.css'
import styled from 'styled-components/macro'
import { useState } from 'react'

export default function App() {
  const apiUrl = process.env.REACT_APP_BASE_URL_API
  const [randomName, setRandomName] = useState('Alex Jonas')

  const getNameCombination = () => {
    fetch(apiUrl + 'g.php')
      .then((response) => response.json())
      .then(({ v, n }) => setRandomName(v + ' ' + n))
  }

  return (
    <PageLayout>
      <h1>Real Name Creator</h1>
      <button onClick={getNameCombination}>Hallo</button>
      <h2>{randomName}</h2>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. At excepturi
        rerum suscipit provident aliquid delectus fugiat laboriosam tempore
        dolores. Dolor neque nisi tenetur pariatur molestias accusamus vitae
        cupiditate consequuntur sint.
      </p>
    </PageLayout>
  )
}

const PageLayout = styled.div`
  display: grid;
  grid-template-rows: 80px auto;
  position: relative;
  height: 100vh;
  margin: 0;
`
